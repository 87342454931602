import React from 'react'

import './style/copyright-infringement-policy.scss'

const CopyrightInfringementPolicy = () => (
  <div className="copyright-infringement-policy">
    <div className="container">
      <section className="policy-agree">
        <div className="row">
          <div className="col-sm-12">
            <h2>Copyright Infringement Policy</h2>
            <p>WebsiteBuilder takes intellectual property rights very seriously and it is our policy to respond to clear notices of alleged copyright infringement. This
            Copyright Infringement Policy describes the information that should be present in these notices. It is designed to make submitting notices of alleged
            infringement to us as straightforward as possible while reducing the number of notices that we receive that are fraudulent or difficult to verify.</p>
          </div>
        </div>
      </section>
    </div>

    <div className="container tos">
      <h1 className="h2">Copyright Infringement Policy</h1>
      <div className="row">
        <div className="col-xs-12">
          <ul className="legal">
            <p>To file a notice of alleged infringement with us, you must provide a written notice that includes your full contact information and sets forth the items
              specified below.</p><p></p><ol><li>The name, address, phone number, email address (if available) and physical or electronic signature of the copyright
              owner or a person authorized to act on the copyright owner’s behalf; </li><li>Identification of the copyrighted work(s); </li><li>Identification of the
              infringing material you are asking us to remove or disable, and the Internet location of the infringing material; </li><li>A statement that you havea
              good faith belief that use of the disputed material is not authorized by the copyright owner, its agent or the law; and </li><li>A statement that the
              information in the complaint is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that
              is allegedly infringed. </li><li>Your signature </li></ol><p></p><p>Please email the signed notification to
              <a href="mailto:abuse@WebsiteBuilder.com"> abuse@WebsiteBuilder.com</a>.</p><p>Alternatively, you may mail the signed notification to the following
              address:</p><p>Newfold Digital 5335 Gate Pkwy, 2nd Floor, Jacksonville, FL 32256</p><p>Please note that you will be liable
              for damages (including costs and attorneys' fees) if you materially misrepresent that material is infringing your copyright. Accordingly, if you are not
              sure whether material available online infringes your copyright, we suggest that you first contact an attorney.</p><p>WebsiteBuilder may respond to
              take-down notices by removing or disabling access to the allegedly infringing material and/or by terminating services. If we remove or disable access in
              response to such a notice, we will make a good-faith attempt to contact the owner or administrator of the affected site or content.</p><p>WebsiteBuilder
              may document notices of alleged infringement it receives and/or on which we action is taken. As with all legal notices, a copy of the notice may be made
              available to the public and sent to one or more third parties who may make it available to the public.</p><p>Last updated: October 07, 2022</p>
          </ul>
        </div>
      </div>
    </div>
  </div>
)
export default CopyrightInfringementPolicy
